<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide"> POSTAL SERVICES ISSUE REPORT</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged">
                  </flat-pickr>
              </div>
            </td>
            
            <td>
              <div class="label_and_element_wrapper">
                <label>
                Document Types
                  <span></span>
                </label>
                  <select v-model="post_data.postal_document_type_id">
                    <option value="">All</option>
                    <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocuments'+i" :value="postaldocument.id">{{postaldocument.postal_document_type}}</option>
                  </select>
              </div>
          </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  GL Code
                  <span></span>
                </label>
                <input type="text" v-model="post_data.code_value">
              </div>
            </td>
</tr>
<tr>

          <td>
            <!-- <div class="label_and_element_wrapper">
              <label>
                Carrier
                <span></span>
              </label>
                  <select v-model="post_data.provider_id" @change="fetchPostalDocument">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
              </div> -->
              <div class="label_and_element_wrapper">
                <label>
                  Matter Number
                  <span></span>
                </label>
                <input type="text" v-model="post_data.matter_number">
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                 Client category
                  <span></span>
                </label>
                  <select v-model="post_data.client_category">
                    <option value="">All</option>
                    <option value="kwm">KWM</option>
                    <option value="base client">Base</option>
                    <option value="personal">Personal</option>
                  </select>
              </div>
            </td>
            <td v-if="'kwm' == post_data.client_category">
              <div class="label_and_element_wrapper">
                <label>
                 Client branches
                  <span></span>
                </label>
                  <select v-model="post_data.client_category_branch">
                    <option value="">All</option>
                    <option
                      v-for="(branch,i) in branches"
                      :key="'branches'+i"
                      :value="branch.name"
                    >{{branch.name}}</option>
                  </select>
              </div>
            </td>

          </tr>
          <tr>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Postal Services Issue Report {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>CodeCat</th>
                <th>AccCode</th>
                <th>RefNextMonth</th>
                <th>Accounting Code</th>
                <th>Currency</th>
                <th>RefCode</th>
                <th>RefYearMonth</th>
                <th>RefItem</th>
                <th>Date</th>
                <th>Sender</th>
                <th  style="width:250px">Joint Code</th>
                <th>Receiver</th>
                <th>Matter Code</th>
                <th>GL Code</th>
                <th>Tracking Number</th>
                <th>Quantity</th>
                <th>Bag Size</th>
                <th>Cost</th>
                <th>GST</th>
                <th>Less GST</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="data in reports">
                <template v-if="Object.keys(data.references).length">
                  <tr v-for="reference in data.references">
                    <td>
                      {{ data.postal_document_type ? getCode(data.postal_document_type.postal_document_type) : ''}}
                      <!-- Code -->
                    </td>
                    <td>
                      POS
                      <!-- CodeCat -->
                    </td>
                    <td>
                      AUGSTINPUT
                      <!-- AccCode -->
                    </td>
                    <td>
                      {{ data.issue_by_date |  nextMonthDate}}
                      <!-- RefNextMonth -->
                    </td>
                    <td>
                      10018101-010
                      <!-- Accounting Code -->
                    </td>
                    <td>
                      AUD
                      <!-- Currency -->
                    </td>
                    <td>
                      FXAP
                      <!-- RefCode -->
                    </td>
                    <td>
                      {{data.issue_by_date | yearMonth}}
                      <!-- RefYearMonth -->
                    </td>
                    <td>
                      E1
                      <!-- RefItem -->
                    </td>
                    <td>
                      {{data.issue_by_date | formatDate}}
                      <!-- Date -->
                    </td>
                    <td>
                      {{ data.requester_name }}
                      <!-- Sender -->
                    </td>
                    <td>
                      {{ data.postal_document_type ? data.postal_document_type.postal_document_type : ''}}  - {{data.issue_by_date | formatDateCode}} - {{ data.requester_name }}
                      <!-- Joint Code -->
                    </td>
                    <td>
                      {{ data.receiver_name }}
                      <!-- Receiver -->
                    </td>
                    <td>
                      {{ data.matter_number ? data.matter_number.matter_number : '' }}
                      <!-- Matter Code -->
                    </td>
                    <td>
                      {{ (data.custom_code_value && data.custom_code == 'GL Code') ? glcodeGenerate(data.custom_code_value) : data.custom_code_value }}
                      <!-- GL Code -->
                    </td>
                    <td>
                      {{ reference.reference_number }}
                      <!-- Tracking Number -->
                    </td>
                    <td>
                      <!-- {{ data.issue_qty }} -->
                      1
                      <!-- Quantity -->
                    </td>
                    <td>
                      {{ data.postal_document_type ? data.postal_document_type.postal_document_name : '' }}
                      <!-- Bag Size -->
                    </td>
                    <td>
                      {{ data.unit_price }}
                      <!-- Cost -->
                    </td>
                    <td>
                      {{ (data.unit_price / 11).toFixed(2) }}
                      <!-- GST -->
                    </td>
                    <td>
                      {{ (data.unit_price - (data.unit_price / 11)).toFixed(2)}}
                      <!-- Less GST -->
                    </td>
                  </tr>
                </template>
                <template  v-else>
                <tr>
                  <td>
                    {{ data.postal_document_type ? getCode(data.postal_document_type.postal_document_type) : ''}}
                    <!-- Code -->
                  </td>
                  <td>
                    POS
                    <!-- CodeCat -->
                  </td>
                  <td>
                    AUGSTINPUT
                    <!-- AccCode -->
                  </td>
                  <td>
                    {{ data.issue_by_date |  nextMonthDate}}
                    <!-- RefNextMonth -->
                  </td>
                  <td>
                    10018101-010
                    <!-- Accounting Code -->
                  </td>
                  <td>
                    AUD
                    <!-- Currency -->
                  </td>
                  <td>
                    FXAP
                    <!-- RefCode -->
                  </td>
                  <td>
                    {{data.issue_by_date | yearMonth}}
                    <!-- RefYearMonth -->
                  </td>
                  <td>
                    E1
                    <!-- RefItem -->
                  </td>
                  <td>
                    {{data.issue_by_date | formatDate}}
                    <!-- Date -->
                  </td>
                  <td>
                    {{ data.requester_name }}
                    <!-- Sender -->
                  </td>
                  <td>
                    {{ data.postal_document_type ? data.postal_document_type.postal_document_type : ''}}  - {{data.issue_by_date | formatDateCode}} - {{ data.requester_name }}
                    <!-- Joint Code -->
                  </td>
                  <td>
                    {{ data.receiver_name }}
                    <!-- Receiver -->
                  </td>
                  <td>
                    {{ data.matter_number ? data.matter_number.matter_number : '' }}
                    <!-- Matter Code -->
                  </td>
                  <td>
                    {{ (data.custom_code_value && data.custom_code == 'GL Code') ? glcodeGenerate(data.custom_code_value) : data.custom_code_value }}
                    <!-- GL Code -->
                  </td>
                  <td>
                    {{ data.reference_number }}
                    <!-- Tracking Number -->
                  </td>
                  <td>
                    {{ data.issue_qty }}
                    <!-- Quantity -->
                  </td>
                  <td>
                    {{ data.postal_document_type ? data.postal_document_type.postal_document_name : '' }}
                    <!-- Bag Size -->
                  </td>
                  <td>
                    {{ data.unit_price }}
                    <!-- Cost -->
                  </td>
                  <td>
                    {{ (data.unit_price / 11).toFixed(2) }}
                    <!-- GST -->
                  </td>
                  <td>
                    {{ (data.unit_price - (data.unit_price / 11)).toFixed(2)}}
                    <!-- Less GST -->
                  </td>
                </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      services: [],
      postaldocuments: [],
      costcenters:[],
      branches:[],
      reports: [],
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        provider_id:"",
        service_providers_id:"",
        postal_document_type_id:"",
        code_value:"",
        matter_number:"",
        issued:"yes",
        via:"issue_by_date",
        client_category:"",
        client_category_branch:"",
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
       save_data:
      {
        actual_courier_amount:"",
        is_actual_courier_amount:"",
        courier_amount_comment:"",

      },
      show_data: false,
    };
  },
  async created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", " Postal Services Issue Report"]);
    await this.fetchCarrier();
    await this.fetchPostalDocument();
    await this.fetchCostCenter();
    await this.fetchBranch();
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatDateCode(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMMDD')
    },
    nextMonthDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).add(1, 'M').format('YYYYMM') + '01';
    },
    yearMonth(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMM')
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('LT')
    },
  },
  methods: {
    getCode(documentType) {
      let code = documentType.split(' - ')
      if(!code.length) {
        return '';
      }

      return code[0]
    },
    glcodeGenerate(dataGlCode) {
      // let glcode = '21100-000000-'

      // glcode += "21100-000000-"
      // glcode += dataGlCode.section + "-"
      // glcode += dataGlCode.department + "-"
      // glcode += dataGlCode.office + "-"
      // glcode += dataGlCode.entity + "-"
      // glcode += "000"

      // return glcode

      return `21100-000000-${dataGlCode}`;
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchCarrier(){
      this.axios.get("/api/provider/carrierlist/")
      .then(response => {
          this.carriers = response.data.providers;
         // this.post_data.provider_id = "8e00bfb6b2294c95a0808f8327dd82dd";  
          //this.fetchService();
       
      })
      .catch(error =>{
          this.toast.error();
      });
    },

  fetchCostCenter()
    {
      this.axios.get("/api/cost/")
      .then(response =>{
        this.costcenters =response.data.cost_centers;
      })
    },
   fetchBranch() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.branches = response.data.branches;
        })
        .catch(error => {
          console.log(error);
        });
    },

    
     fetchService() {
      if(this.post_data.provider_id){
      this.axios.get("/api/provider/carrier/"+this.post_data.provider_id)
        .then(response => {
          this.services = response.data.provider.servicenames;
          if(this.services.length > 0){
            if(this.post_data.provider_id == "8e00bfb6b2294c95a0808f8327dd82dd")
              this.post_data.service_providers_id = "ac0fca91948d476cbf14871c2a8a35f5" ; 
            else
              this.post_data.service_providers_id = this.services[0].id;
            }


          })
       
        .catch(error => {
          console.log(error);
        });
      }
    },

     // to get postal document type
  
     fetchPostalDocument(){
      this.axios.get("/api/postaldocument")
      .then(response => {
          this.postaldocuments = response.data.postal_document_types;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    resetData() {
      this.show_data = false;
      // if (this.post_data.booking_type != "Transport") {
         //this.post_data.delivery_provider='all'
       //  this.post_data.type_of_good='all'
       //}
       //else{
         //this.post_data.delivery_provider='Transport'
         // this.post_data.type_of_good='corporate'
       //}
      this.reports = {};
    },
    fetchReport() {
      this.axios
        .post("/api/reports/get-postal-issue-encode-report", this.post_data)
        .then(response => {
          this.show_data = true;
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getpostalserviceissuereport/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 25%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>